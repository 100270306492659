<template>
  <v-dialog
    v-model="open"
    max-width="800"
    persistent
    scrollable
  >
    <v-card>
      <v-toolbar
        color="primary"
        dark
      >
        <v-spacer />
        <v-toolbar-title>
          {{ isEditMode ? `Edit ${title}` : `Create ${title}` }}
        </v-toolbar-title>
        <v-spacer />
        <v-icon @click="onClose">
          mdi-close
        </v-icon>
      </v-toolbar>
      <v-card-text class="pa-0">
        <v-container class="pa-7">
          <component
            :is="formName || 'generic-form'"
            ref="genericForm"
            :fields-config="fieldsConfig"
            :data="data"
            :loading="loading"
            :btn-labels="btnLabels"
            @onCancel="onClose"
            @onSubmit="(payload) => $emit('onSubmit', payload)"
          />
          <p class="error--text text-center mt-2">
            <strong>{{ error }}</strong>
          </p>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import GenericForm from '@/shared/forms/GenericForm.vue';
import CreateUpdateCarForm from '@/shared/forms/CreateUpdateCarForm.vue';
import axios from 'axios';
import { isEmpty } from 'lodash';

export default {
    name: 'CreateUpdateModal',
    components: {
        GenericForm,
        CreateUpdateCarForm,
    },
    props: {
        open: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: 'Record',
        },
        formName: {
            type: String,
            default: 'generic-form',
        },
        fieldsConfig: {
            type: Array,
            default: () => [],
        },
        data: {
            type: Object,
            default: () => {},
        },
        error: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            isEditMode: false,
            currentStep: 1,
            roles: [],
            steps: [{ title: 'create user' }, { title: 'assign role' }],
        };
    },
    computed: {
        btnLabels() {
            return {
                cancel: 'Cancel',
                submit: this.isEditMode ? 'Update' : 'Create',
            };
        },
        showNextBtn() {
            return this.currentStep < this.steps.length;
        },
        isNextBtnDisabled() {
            return this.currentStep >= this.steps.length;
        },
    },
    watch: {
        open(newValue) {
            if (newValue) {
                this.isEditMode = !isEmpty(this.data);
            }
        },
    },
    methods: {
        onBack() {
            this.currentStep -= 1;
        },
        onNext() {
            if (this.$refs.genericForm.validate()) {
                this.currentStep += 1;
            }
        },
        onClose() {
            this.currentStep = 1;
            this.$refs.genericForm.activeTab = 0;
            this.$emit('close');
        },
        async getRoles() {
            const reponse = await axios.get('/roles');
            this.roles = reponse.dataItems;
            console.log(typeof this.roles);
        },
    },
};
</script>
